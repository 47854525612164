import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div>
          {"Made with"} &#128151; {"by Shashwat Rathod"}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
